import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import OrderContext from "../../context/bulk/orders/ordersContext";
import SweetAlertWrapper from '../../components/Layout/SweetAlert';
import { useNavigate } from 'react-router-dom';

const RemoveOrderModal = ({ show, handleClose, orderId }) => {
    const navigate = useNavigate();
    const orderContext = useContext(OrderContext);
    const { removeOrder } = orderContext;

    

    

    const handleCancelPlan = () => {
        removeOrder(orderId);
    };

    return (
        <div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Plan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to cancel this plan?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCancelPlan}>
                        Cancel Plan
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default RemoveOrderModal;
