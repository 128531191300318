import React from "react";
import { useParams } from "react-router-dom";
import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import Main from '../../components/Layout/Main';
import { Button } from 'react-bootstrap';

const ViewCustomer = () => {
    const { customerId } = useParams();
    
    // Static customer data
    const customers = [
        { customerId: "1", name: "John Doe", email: "john.doe@example.com", phone: "123-456-7890" },
        { customerId: "2", name: "Jane Smith", email: "jane.smith@example.com", phone: "987-654-3210" },
        // Add more static customers as needed
    ];

    // Static orders data
    const orders = [
        { id: "101", customerId: "1", status: "Ca", amount: 100, date: "2024-08-01" },
        { id: "102", customerId: "1", status: "pending", amount: 200, date: "2024-08-02" },
        { id: "103", customerId: "2", status: "Ca", amount: 150, date: "2024-08-03" },
        // Add more static orders as needed
    ];

    // Find the customer by customerId
    const customer = customers.find(customer => customer.customerId === customerId);

    // Filter orders by customerId
    const customerOrders = orders.filter(order => order.customerId === customerId);

    // Summary of orders
    const totalOrders = customerOrders.length;
    const deliveredOrders = customerOrders.filter(order => order.status === "Ca").length;
    const pendingOrders = customerOrders.filter(order => order.status === "pending").length;

    return (
        <div className="niceadmin">
            <Header />
            <Sidebar page="View Customer" />
            <Main>
                {/* {customer ? ( */}
                    <div className="card mb-4">
                        <div className="card-body">
                            <h3>New Customer</h3>
                            <p>customer@gmail.com</p>
                            <p> 071122334455</p>
                            <button className="btn btn-primary">Edit</button>
                        </div>
                    </div>
                {/* ) 
                : (
                    <p>Customer not found</p>
                )} */}
                <div className="row">
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <h5>Total Orders</h5>
                                <p>{totalOrders}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <h5>Delivered Orders</h5>
                                <p>{deliveredOrders}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <h5>Pending Orders</h5>
                                <p>{pendingOrders}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mt-4">
                    <div className="card-body">
                        <h4>Customer Orders</h4>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Status</th>
                                    <th>Amount</th>
                                    <th>Date</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {customerOrders.map(order => (
                                    <tr key={order.id}>
                                        <td>{order.id}</td>
                                        <td>{order.status}</td>
                                        <td>{order.amount}</td>
                                        <td>{new Date(order.date).toLocaleDateString()}</td>
                                        <td>
                                            <Button variant="primary">View</Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Main>
        </div>
    );
}

export default ViewCustomer;
