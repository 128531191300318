import React, { useContext, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import OrderContext from "../context/bulk/orders/ordersContext";
import { Link } from "react-router-dom";

function Dashboard() {
  const orderContext = useContext(OrderContext);
  const { dashBoardData, getDashboardData,
    // montlyDashboardData, getMontlyDashboardData,
    // weeklyDashboardData, getWeeklyDashboardData,
  } = orderContext;

  useEffect(() => {
    getDashboardData();
  }, [dashBoardData]);

  const vehicleSummary = [
    { title: "Total Vehicles", value: dashBoardData?.totalVehicles || 0, icon: "bi-truck" },
    { title: "Total Active Vehicles", value: dashBoardData?.totalActiveVehicles || 0, icon: "bi-speedometer2" },
  ];

  const customerSummary = [
    { title: "Total Customers", value: dashBoardData?.totalCustomers || 0, icon: "bi-people" },
    { title: "Customers Served Today", value: dashBoardData?.totalCustomersAddedToday || 0, icon: "bi-person-plus" },
  ];

  const series = [
    {
      name: "Deliveries",
      data: [
        dashBoardData?.totalDeliveredToday || 0,
        dashBoardData?.totalDeliveredThisMonth || 0,
        dashBoardData?.totalDeliveredThisYear || 0,
      ],
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "bar",
    },
    xaxis: {
      categories: ["Today", "This Month", "This Year"],
    },
    dataLabels: {
      enabled: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    legend: {
      position: 'top',
    },
    title: {
      text: 'Delivery Trends',
      align: 'left',
    },
  };

  return (
    <React.Fragment>
      <div className="pagetitle">
        <h1>Dashboard</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="/">Home</a></li>
            <li className="breadcrumb-item active">Dashboard</li>
          </ol>
        </nav>
      </div>

      <section className="section dashboard">
        <div className="row">

          {/* Customer Summary Card */}
          <div className="col-xxl-4 col-md-6 mb-3">
            <Link to="/bulk/customers" className="text-decoration-none" style={{ cursor: 'pointer' }}>
              <div className="card">
                <div className="card-body p-2">
                  <h6 className="card-title mb-2">Customer Summary</h6>
                  <div className="d-flex justify-content-between">
                    {customerSummary.map((item, index) => (
                      <div className="d-flex align-items-center" key={index} style={{ minWidth: '0', flex: '1' }}>
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center" style={{ width: '30px', height: '30px' }}>
                          <i className={`bi ${item.icon} fs-6`}></i>
                        </div>
                        <div className="ps-2 text-truncate">
                          <h6 className="text-dark mb-0" style={{ fontSize: '14px' }}>{item.value}</h6>
                          <span className="text-muted small" style={{ fontSize: '12px' }}>{item.title}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Link>
          </div>

          {/* Vehicle Summary Card */}
          <div className="col-xxl-4 col-md-6 mb-3">
            <Link to="/bulk/vehicles" className="text-decoration-none card-link">
              <div className="card">
                <div className="card-body p-2">
                  <h6 className="card-title mb-2">Vehicle Summary</h6>
                  <div className="d-flex justify-content-between">
                    {vehicleSummary.map((item, index) => (
                      <div className="d-flex align-items-center" key={index} style={{ minWidth: '0', flex: '1' }}>
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center" style={{ width: '30px', height: '30px' }}>
                          <i className={`bi ${item.icon} fs-6`}></i>
                        </div>
                        <div className="ps-2 text-truncate">
                          <h6 className="text-dark mb-0" style={{ fontSize: '14px' }}>{item.value}</h6>
                          <span className="text-muted small" style={{ fontSize: '12px' }}>{item.title}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Link>
          </div>

          {/* Total Delivered Today Card */}
          <div className="col-xxl-4 col-md-6 mb-3">
            <Link to="/bulk/orders" className="text-decoration-none card-link">
              <div className="card">
                <div className="card-body p-2">
                  <h6 className="card-title mb-2">Total Delivered Today</h6>
                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center" style={{ width: '30px', height: '30px' }}>
                      <i className="bi bi-box-seam fs-6"></i>
                    </div>
                    <div className="ps-2">
                      <h6 className="text-dark mb-0" style={{ fontSize: '14px' }}>{dashBoardData?.totalDeliveredToday || 0}</h6>
                      <span className="text-muted small" style={{ fontSize: '12px' }}>Deliveries Today</span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          {/* Delivery Trends Chart */}
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Delivery Trends</h5>
                <ReactApexChart options={options} series={series} type="bar" height={350} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Dashboard;
