import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import OrderContext from '../../context/bulk/orders/ordersContext';
import SweetAlert from 'react-bootstrap-sweetalert';
import SweetAlertWrapper from '../../components/Layout/SweetAlert';

const DetachOrderModal = ({ show, handleClose, deliveryPlan }) => {
    const orderContext = useContext(OrderContext);
    const {removeOrder} = orderContext;
    const [selectedOrders, setSelectedOrders] = useState([]);

    const orderOptions = deliveryPlan?.orders?.map(order => ({
        value: order.orderId,
        label: `${order.customerName} - ${order.locationName} - ${order.orderedQuantity}`
    })) || [];

    
    useEffect(() => {
        // Pre-select the existing orders when the modal is opened
        if (deliveryPlan && deliveryPlan.orderIds) {
            const existingOrders = deliveryPlan.orderIds.map(orderId => ({
                value: orderId,
                label: orderId // Modify this label as needed to show more information
            }));
            // setSelectedOrders(existingOrders);
        }
    }, [deliveryPlan]);

    const handleDetachOrder = () => {
        const orderIds = selectedOrders.map(order => order.value);
        // Implement your detach order logic here
        console.log('Detaching Orders:', {
            deliveryPlanId: deliveryPlan.deliveryPlanId,
            orderIds
        });
        removeOrder(deliveryPlan.deliveryPlanId, orderIds);
        handleClose(); // Close the modal after detaching
    };

    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Detach Orders</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formOrders">
                            <Form.Label>Select Orders</Form.Label>
                            <Select
                                isMulti
                                options={orderOptions}
                                value={selectedOrders}
                                onChange={setSelectedOrders}
                                placeholder="Select orders..."
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleDetachOrder}>
                        Detach Orders
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default DetachOrderModal;
