import React, { useState, useEffect, useContext } from 'react';
import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import Main from '../../components/Layout/Main';
import OrderContext from "../../context/bulk/orders/ordersContext";
import { Modal, Button } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const ViewDeliveryPlan = ({ show, handleClose, deliveryPlanId }) => {
  const orderContext = useContext(OrderContext);
  const { orders, getOrders, clear_notification } = orderContext;


  // Example Base64 image string (this is just a placeholder)
  const base64Image = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAOVBMVEX///8BCTU9QFu+v8j///8ZHD6HiZr///////////8AAC3HyNFLTWIRFTovMk6Zmqiwsbxoan5VVm0gKF6wAAAACnRSTlN7////Ov//vMNb4ZHIqQAAAMpJREFUOI2V0+EOgyAMRlFpVzaFrxTe/2GHOrc4tcuu8ZcnSrAMt8GtP06cnLqJk1OcQZAe5KzwAUrvVE8AWeItK1W/ATJXorxc1CxZPgICIPMtmJRfYg8+awPYyAMCTdUFEkpRF6BxhgeEfgD8BNV8ECy6iww1NTggNF5ecAQIvUkjl9V/A62lZ9wXgNN/kYvFufb+1h6glSp9XAAH7PfzTwBkD/SRo3oN1qFN1i7ANvYqcgXmQcTh9KxgCpctR4+jEw/D+Lg7PcYnAA8YCe81lqEAAAAASUVORK5CYII=";

  return (
    <div>
      <Header />
      <Sidebar />
      <Main>
       
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delivery Note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={base64Image} alt="Delivery Plan" style={{ width: '100%' }} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Main>
    </div>
  );
};

export default ViewDeliveryPlan;
