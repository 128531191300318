import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import AlertContext from '../context/alerts/alertContext'
import AuthContext from '../context/auth/authContext'
import Alerts from '../components/Layout/Alerts';

const Login = () => {


  const navigate = useNavigate();

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const authContext = useContext(AuthContext);
  const { login, error, clearErrors, isAuthenticated } = authContext;
  const [user, setUser] = useState({
    username: "",
    password: ""
  });


  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
};

  const { username, password } = user;

  useEffect(() => {
    if (isAuthenticated) {
      setAlert('Login Successful', 'success');
      navigate('/');
    }
    if(error){
      setAlert(error, 'danger');
      clearErrors();
    }

  }, [isAuthenticated, error]);

  const onChange = e => {
    return setUser({
      ...user,
      [e.target.name]: e.target.value
    });
  };


  const onSubmit = e => {
    e.preventDefault();
    if (username === "" || password === "") {
      setAlert("Please fill in all fields", "danger");
    } else {
      login({ userName: username, password });
    }
  };

  return (
    <div>
     
      <div className="container">

        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
          
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                <div className="d-flex justify-content-center py-4">
                  <a href="index.html" className="logo d-flex align-items-center w-auto">

                   
                  </a>
                </div>

                <div className="card mb-3">

                  <div className="card-body">

                  

                    <div className="pt-4 pb-2">
                   
                      <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                      <p className="text-center small">Enter your username & password to login</p>
                    </div>

                    <form className="row g-3 needs-validation" noValidate>

                      <div className="col-12">
                      <Alerts />
                        <label htmlFor="username" className="form-label">Username</label>
                        <div className="input-group has-validation">
                          {/* <span className="input-group-text" id="inputGroupPrepend">@</span> */}
                          <input type="text" onChange={onChange} name="username" className="form-control" autoComplete="on" id="Username" required value={username}  />
                          <div className="invalid-feedback">Please enter your username.</div>
                        </div>
                      </div>

                      <div className="col-12">
                        <label htmlFor="password" className="form-label">Password</label>
                        <div className="input-group has-validation">
                          <input type={showPassword ? "text" : "password"} onChange={onChange} name="password" className="form-control" id="Password" required value={password} />
                          <div className="invalid-feedback">Please enter your password.</div>
                          <button className="btn btn-outline-secondary" type="button" onClick={togglePasswordVisibility}>
                            <i className={`bi bi-eye${showPassword ? "-slash" : ""}`}></i>
                          </button>
                        </div>
                      </div>

                      {/* forgot password? */}
                      <div className="col-12">
                        <a href="/forgot-password" className="small text-decoration-none">Forgot password?</a>
                      </div>



                      {/* <div className="col-12">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe" />
                          <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                        </div>
                      </div> */}
                      <div className="col-12">
                        <button className="btn btn-primary w-100" type="submit" onClick={onSubmit}>Login</button>
                      </div>
                     
                    </form>

                  </div>
                </div>


              </div>
            </div>
          </div>

        </section>

      </div>


    </div>
  )
}

export default Login