import React, { useState, useContext } from 'react';
import UserContext from '../../context/users/userContext';
import { useNavigate } from 'react-router-dom';


const RequestOtp = ({ userId }) => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { requestOtp } = userContext;
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    requestOtp(userId, phoneNumber);
    navigate('/otp-verification');
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title mb-4">Request OTP</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                  <input
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    className="form-control"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-primary">Request OTP</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestOtp;
