import React, { useState, useEffect, useContext } from 'react';
import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import Main from '../../components/Layout/Main';
import { Link } from 'react-router-dom';
import CustomerContext from '../../context/bulk/customers/customersContext';

const ListDrivers = () => {
  const customerContext = useContext(CustomerContext);
  const { drivers, listDrivers } = customerContext;

  const [search, setSearch] = useState(''); // State for search input
  const [currentPage, setCurrentPage] = useState(0); // State for current page
  const [driversPerPage] = useState(10); // Number of drivers per page

  useEffect(() => {
    listDrivers();
  }, []);

  const page = 'List Drivers';

  // Filter drivers based on the search query
  const filteredDrivers = drivers.filter((driver) =>
    driver.names.toLowerCase().includes(search.toLowerCase()) ||
    driver.phoneNumber.includes(search) ||
    driver.userCode.includes(search)
  );

  // Get current drivers
  const indexOfLastDriver = (currentPage + 1) * driversPerPage;
  const indexOfFirstDriver = indexOfLastDriver - driversPerPage;
  const currentDrivers = filteredDrivers.slice(indexOfFirstDriver, indexOfLastDriver);

  // Handle page change
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  return (
    <div className="niceadmin">
      <Header />
      <Sidebar page={page} />
      <Main>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">List Drivers</h5>

            {/* Search Input */}
            <Link className="btn btn-primary mb-3" id="add-user" to="/bulk/drivers/add">
              Add Driver
            </Link>

            <input
              type="text"
              className="form-control mb-3"
              placeholder="Search Drivers"
              value={search}
              onChange={(e) => setSearch(e.target.value)} // Update search state on input change
            />

            <table className="table datatable">
              <thead>
                <tr>
                  <th scope="col">Driver ID</th>
                  <th scope="col">Driver Name</th>
                  <th scope="col">Contact</th>
                </tr>
              </thead>
              <tbody>
                {currentDrivers.map((driver) => (
                  <tr key={driver.userCode}>
                    <td>{driver.userCode}</td>
                    <td>{driver.names}</td>
                    <td>{driver.phoneNumber}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Main>
    </div>
  );
};

export default ListDrivers;
