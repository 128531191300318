import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import SweetAlertWrapper from "../../components/Layout/SweetAlert";
import CustomersContext from "../../context/bulk/customers/customersContext";
import Sidebar from "../../components/Layout/Sidebar";
import Header from "../../components/Layout/Header";
import Main from "../../components/Layout/Main";

const AddCustomers = () => {
    const navigate = useNavigate();

    const customersContext = useContext(CustomersContext);
    const { addCustomer, updateCustomer, notification, clear_notifications, selected_customer } = customersContext;

    const page = "Add Customer";

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });

    // Set up form validation with react-hook-form
    const { register, handleSubmit, formState: { errors, isValid }, reset } = useForm({
        mode: 'onChange', // Validate on change
        defaultValues: {
            customerName: selected_customer ? selected_customer.customerName : '',
            customerPhone: selected_customer ? selected_customer.customerPhone : '',
            customerEmail: selected_customer ? selected_customer.customerEmail : '',
            krapin: selected_customer ? selected_customer.krapin : '',
            customerType: selected_customer ? selected_customer.customerType : 'Bulk',
        }
    });

    // SweetAlert confirmation
    const handleConfirm = () => {
        setShowAlert(false);
        navigate('/bulk/customers');
        clear_notifications();
    };

    // Listen to notification change
    useEffect(() => {
        if (notification) {
            setAlertMessage({ text: notification.text, type: notification.type });
            setShowAlert(true);
        }
    }, [notification]);

    const onSubmit = (data) => {
        if (selected_customer) {
            updateCustomer(data);
        } else {
            addCustomer(data);
        }
        reset(); // Reset form after submission
    };

    return (
        <div className="niceadmin">
            <Header />
            <Sidebar page={page} />
            <Main>
                <SweetAlertWrapper
                    show={showAlert}
                    title="Success"
                    message={alertMessage.text}
                    onConfirm={handleConfirm}
                    confirmBtnText="OK"
                    type={alertMessage.type}
                />
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <div className="align-center">
                                            <h5 className="card-title">Add Customer</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-4"></div>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group">
                                        <label htmlFor="customerName">Name</label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.customerName ? 'is-invalid' : ''}`}
                                            id="customerName"
                                            name="customerName"
                                            {...register('customerName', { required: "Customer name is required" })}
                                        />
                                        {errors.customerName && <div className="invalid-feedback">{errors.customerName.message}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="customerPhone">Phone</label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.customerPhone ? 'is-invalid' : ''}`}
                                            id="customerPhone"
                                            name="customerPhone"
                                            {...register('customerPhone', {
                                                required: "Phone number is required",
                                                pattern: {
                                                    value: /^[0-9]{10}$/,
                                                    message: "Phone number must be 10 digits"
                                                }
                                            })}
                                        />
                                        {errors.customerPhone && <div className="invalid-feedback">{errors.customerPhone.message}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="customerEmail">Email</label>
                                        <input
                                            type="email"
                                            className={`form-control ${errors.customerEmail ? 'is-invalid' : ''}`}
                                            id="customerEmail"
                                            name="customerEmail"
                                            {...register('customerEmail', {
                                                required: "Email is required",
                                                pattern: {
                                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                    message: "Invalid email address"
                                                }
                                            })}
                                        />
                                        {errors.customerEmail && <div className="invalid-feedback">{errors.customerEmail.message}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="krapin">KRA PIN</label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.krapin ? 'is-invalid' : ''}`}
                                            id="krapin"
                                            name="krapin"
                                            {...register('krapin', {
                                                required: "KRA PIN is required",
                                                minLength: {
                                                    value: 4,
                                                    message: "KRA PIN must be at least 4 characters"
                                                }
                                            })}
                                        />
                                        {errors.krapin && (
                                            <div className="invalid-feedback">
                                                {errors.krapin.message}
                                            </div>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="customerType">Type</label>
                                        <select
                                            className={`form-control ${errors.customerType ? 'is-invalid' : ''}`}
                                            id="customerType"
                                            name="customerType"
                                            {...register('customerType', { required: "Customer type is required" })}
                                        >
                                            <option value="Bulk">Bulk</option>
                                            <option value="Otogas">Otogas</option>
                                        </select>
                                        {errors.customerType && <div className="invalid-feedback">{errors.customerType.message}</div>}
                                    </div>

                                    <div className="bx-pull-right mt-4">
                                        <button type="submit" className="btn btn-primary" disabled={!isValid}>
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </Main>
        </div>
    );
};

export default AddCustomers;
