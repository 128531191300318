import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import OrderContext from "../../context/bulk/orders/ordersContext";
import CustomersContext from '../../context/bulk/customers/customersContext';
import SweetAlertWrapper from '../../components/Layout/SweetAlert';

const AddPlan = ({ show, handleClose }) => {
    const orderContext = useContext(OrderContext);
    const customersContext = useContext(CustomersContext);
    const { addPlan, notification, clear_notification } = orderContext;
    const { drivers, listDrivers, vehicles, listVehicles } = customersContext;

    const [driverId, setDriverId] = useState(null);
    const [vehicleId, setVehicleId] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });

    // Fetch drivers and vehicles on component mount
    useEffect(() => {
        listDrivers();
        listVehicles();
    }, []);

    // Handle success or error notifications
    const handleConfirm = () => {
        setShowAlert(false);
        handleClose();
    };

    useEffect(() => {
        if (notification) {
            setAlertMessage({ text: notification.text, type: notification.type });
            setShowAlert(true);
        }
    }, [notification]);

    // Handle form submission
    const handleAddPlan = () => {
        addPlan({ driverId, vehicleId });
    };

    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Plan</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        <Form.Group controlId="formDriverId">
                            <Form.Label>Select Driver</Form.Label>
                            <Select
                                options={drivers.map(driver => ({
                                    value: driver.userCode,
                                    label: driver.names
                                }))}
                                onChange={(selectedOption) => setDriverId(selectedOption.value || null)}
                                placeholder="Select a driver"
                                isSearchable
                                isClearable
                            />
                        </Form.Group>

                        <Form.Group controlId="formVehicleId">
                            <Form.Label>Select Vehicle</Form.Label>
                            <Select
                                options={vehicles.map(vehicle => ({
                                    value: vehicle.vehicleId,
                                    label: vehicle.registrationNumber
                                }))}
                                onChange={(selectedOption) => setVehicleId(selectedOption.value || null)}
                                placeholder="Select a vehicle"
                                isSearchable
                                isClearable
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAddPlan}>
                        Add Plan
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AddPlan;
