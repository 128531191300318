import React, { useState, useEffect, useContext } from 'react';
import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import Main from '../../components/Layout/Main';
import { Link } from 'react-router-dom';
import OrdersContext from '../../context/bulk/orders/ordersContext';
import Spinner from '../../components/Layout/spinner';
import LoadOrdersModal from './Load';
import OffloadOrdersModal from './Offload';
import AddPlanModal from './AddPlan';
import AttachOrderModal from './attachOrder';
import CancelPlanModal from './CancelPlan';
import DetachOrderModal from './detachOrder';
import { formatDate } from '../../api/util';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { de } from 'date-fns/locale';
import PatchDeliveryPlanModal from './ReassignDriverOrVehicle';
import SweetAlertWrapper from '../../components/Layout/SweetAlert';
import ViewDeliveryPlan from './viewDeliveryNote';
import userCan from '../../config/user_can';

const ListDeliveryPlans = () => {
    const orderContext = useContext(OrdersContext);
    const { listDeliveryPlans, delivery_plans, totalRecords, notification, clear_notification } = orderContext;

    const [selectedPlanId, setSelectedPlanId] = useState('');
    const [showLoadPlanModal, setShowLoadPlanModal] = useState(false);
    const [showOffLoadPlanModal, setShowOffLoadPlanModal] = useState(false);
    const [showAddPlanModal, setShowAddPlanModal] = useState(false);
    const [showAttachOrderModal, setShowAttachOrderModal] = useState(false);
    const [showCancelDeliveryPlan, setShowCancelDeliveryPlan] = useState(false);
    const [showViewDeliveryPlanModal, setShowViewDeliveryPlanModal] = useState(false);
    const [showDetachOrderModal, setShowDetachOrderModal] = useState(false);
    const [selectedDeliveryPlan, setSelectedDeliveryPlan] = useState(null);
    const [showPatchDeliveryPlanModal, setShowPatchDeliveryPlanModal] = useState(false);
    const [selectedDriverId, setSelectedDriverId] = useState('');
    const [selectedVehicleId, setSelectedVehicleId] = useState('');

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });


    const handleConfirm = () => {
        clear_notification();
        setShowAlert(false);
        setSelectedPlanId('');
        setShowLoadPlanModal(false);
        setShowOffLoadPlanModal(false);
        setShowAddPlanModal(false);
        setShowAttachOrderModal(false);
        setShowCancelDeliveryPlan(false);
        setShowDetachOrderModal(false);
        setShowPatchDeliveryPlanModal(false);
        setShowViewDeliveryPlanModal(false)

    };


    const [searchTerm, setSearchTerm] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const handleShowLoadPlan = (id) => {
        setSelectedPlanId(id);
        setShowLoadPlanModal(true);
    };

    const handleShowOffLoadPlan = (id) => {
        setSelectedPlanId(id);
        setShowOffLoadPlanModal(true);
    };

    const handleShowAddPlan = () => {
        setShowAddPlanModal(true);
    };

    const handleShowAttachOrder = (id) => {
        setSelectedPlanId(id);
        setShowAttachOrderModal(true);
    };

    const handleShowCancelDeliveryPlan = (id) => {
        setSelectedPlanId(id);
        setShowCancelDeliveryPlan(true);
    };

    const handleShowDetachOrder = (delivery_plan) => {
        setSelectedDeliveryPlan(delivery_plan);
        setShowDetachOrderModal(true);
    };

    const handleShowPatchDeliveryPlan = (deliveryPlanId, driverId, vehicleId) => {
        setSelectedPlanId(deliveryPlanId);
        setSelectedDriverId(driverId);
        setSelectedVehicleId(vehicleId);
        setShowPatchDeliveryPlanModal(true);
    };

    const handleShowViewDeliveryPlan = (id) => {
        setSelectedPlanId(id);
        setShowViewDeliveryPlanModal(true);
    };

    const handleClose = () => {
        setSelectedPlanId('');
        setShowLoadPlanModal(false);
        setShowOffLoadPlanModal(false);
        setShowAddPlanModal(false);
        setShowAttachOrderModal(false);
        setShowCancelDeliveryPlan(false);
        setShowDetachOrderModal(false);
        setShowPatchDeliveryPlanModal(false);
        setShowViewDeliveryPlanModal(false);
        clear_notification();
        listDeliveryPlans();
    };

    useEffect(() => {
        listDeliveryPlans(currentPage, itemsPerPage);
    }, [currentPage, itemsPerPage, listDeliveryPlans]);

    useEffect(() => {
        if (notification) {
            setAlertMessage({ text: notification.text, type: notification.type });
            setShowAlert(true);
        }
    }, [notification]);

    const filteredPlans = delivery_plans
        .filter(plan => plan.vehicleRegistrationNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
            plan.driverName.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .filter(plan => filterStatus ? plan.deliveryStatusName === filterStatus : true)
        .filter(plan => {
            if (dateRange[0] && dateRange[1]) {
                const deliveryDate = new Date(plan.dateCreated);
                const startDate = new Date(dateRange[0]);
                const endDate = new Date(dateRange[1]);
                return deliveryDate >= startDate && deliveryDate <= endDate;
            }
            return true;
        });

    const exportToExcel = () => {
        const data = filteredPlans.map(plan => ({
            'Plan ID': plan.deliveryPlanId,
            'Vehicle Registration': plan.vehicleRegistrationNumber,
            'Driver Name': plan.driverName,
            'Date Created': formatDate(plan.dateCreated),
            'Delivery Status': plan.deliveryStatusName,
            'Loaded Quantity': plan.loadedQuantity,
            'OffLoaded Quantity': plan.offLoadedQuantity,
            'Date Loaded': formatDate(plan.dateLoaded),
            'Date OffLoaded': formatDate(plan.dateOffLoaded),
        }));
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Delivery Plans');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(dataBlob, 'Delivery_Plans.xlsx');
    };

    // Pagination calculation
    const indexOfLastPlan = currentPage * itemsPerPage;
    const indexOfFirstPlan = indexOfLastPlan - itemsPerPage;
    const currentPlans = filteredPlans.slice(indexOfFirstPlan, indexOfLastPlan);

    // Pagination controls
    const totalPages = Math.ceil(filteredPlans.length / itemsPerPage);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Calculating stats
    const totalPlans = filteredPlans.length;
    const totalLoadedQuantity = filteredPlans.reduce((sum, plan) => sum + plan.loadedQuantity, 0);
    const totalOffLoadedQuantity = filteredPlans.reduce((sum, plan) => sum + plan.offLoadedQuantity, 0);

    const page = 'List Delivery Plans';

    return (
        <div className="niceadmin">
            <Header />
            <Sidebar page={page} />
            <Main>
                <SweetAlertWrapper
                    show={showAlert}
                    title="Notification"
                    message={alertMessage.text}
                    onConfirm={handleConfirm}
                    confirmBtnText="OK"
                    type={alertMessage.type}
                />
                <AddPlanModal show={showAddPlanModal} handleClose={handleClose} />
                <LoadOrdersModal show={showLoadPlanModal} handleClose={handleClose} deliveryPlanId={selectedPlanId} />
                <OffloadOrdersModal show={showOffLoadPlanModal} handleClose={handleClose} deliveryPlanId={selectedPlanId} />
                <AttachOrderModal show={showAttachOrderModal} handleClose={handleClose} deliveryPlanId={selectedPlanId} />
                <CancelPlanModal show={showCancelDeliveryPlan} handleClose={handleClose} deliveryPlanId={selectedPlanId} />
                <DetachOrderModal show={showDetachOrderModal} handleClose={handleClose} deliveryPlan={selectedDeliveryPlan} />
                <PatchDeliveryPlanModal show={showPatchDeliveryPlanModal} handleClose={handleClose} deliveryPlanId={selectedPlanId} initialDriverId={selectedDriverId} initialVehicleId={selectedVehicleId} />
                <ViewDeliveryPlan show={showViewDeliveryPlanModal} handleClose={handleClose} deliveryPlanId={selectedPlanId} />

                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">List Delivery Plans</h5>

                        {/* Stats Section */}


                        <div className="row mb-4">
                            <div className="col-lg-3 col-md-6 mb-3">
                                <div className="card bg-light" style={{ padding: '5px', height: '120px' }}>
                                    <div className="card-body" style={{ padding: '5px' }}>
                                        <h5 className="card-title" style={{ fontSize: '16px' }}>Total Loaded Quantity</h5>
                                        <p className="card-text display-5" style={{ fontSize: '20px' }}>{totalPlans}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 mb-3">
                                <div className="card bg-success text-white" style={{ padding: '5px', height: '120px' }}>
                                    <div className="card-body" style={{ padding: '5px' }}>
                                        <h5 className="card-title" style={{ fontSize: '16px' }}>Delivered</h5>
                                        <p className="card-text display-5" style={{ fontSize: '20px' }}>{totalLoadedQuantity}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 mb-3">
                                <div className="card bg-warning text-white" style={{ padding: '5px', height: '120px' }}>
                                    <div className="card-body" style={{ padding: '5px' }}>
                                        <h5 className="card-title" style={{ fontSize: '16px' }}>Total OffLoaded Quantity</h5>
                                        <p className="card-text display-5" style={{ fontSize: '20px' }}>{totalOffLoadedQuantity}</p>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <Link className="btn btn-primary mb-3" id="add-user" onClick={handleShowAddPlan}>
                            Add Delivery Plan
                        </Link>
                        <div className="d-flex align-items-center mb-3">
                            <div className="flex-grow-1 me-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by Vehicle Registration or Driver Name"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            <div className="flex-shrink-1 me-2">
                                <select
                                    className="form-control"
                                    value={filterStatus}
                                    onChange={(e) => setFilterStatus(e.target.value)}
                                >
                                    <option value="">All Statuses</option>
                                    <option value="Cancelled">Cancelled</option>
                                    <option value="New Plan">New Plan</option>
                                    <option value="Assigned Orders">Assigned Orders</option>
                                    <option value="Inventory Loaded">Inventory Loaded</option>
                                    <option value="Loaded">Loaded</option>
                                    <option value="Completed">Completed</option>
                                </select>
                            </div>
                            <div className="flex-shrink-1 me-2">
                                <DatePicker
                                    selected={dateRange[0]}
                                    onChange={update => setDateRange(update || [null, null])}
                                    startDate={dateRange[0]}
                                    endDate={dateRange[1]}
                                    selectsRange
                                    className="form-control"
                                    dateFormat="yyyy/MM/dd"
                                    placeholderText="Select Date Range"
                                />
                            </div>
                            <div>
                                <button
                                    className="btn btn-success"
                                    onClick={exportToExcel}
                                    disabled={!dateRange[0] || !dateRange[1]}
                                >
                                    Export to Excel
                                </button>
                            </div>
                        </div>
                        <table className="table datatable">
                            <thead>
                                <tr>
                                    <th scope="col">Plan ID</th>
                                    <th scope="col">Vehicle Registration</th>
                                    <th scope="col">Driver Name</th>
                                    <th scope="col">Date Created</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Loaded Quantity</th>
                                    <th scope="col">OffLoaded Quantity</th>
                                    <th scope="col">Date Loaded</th>
                                    <th scope="col">Date OffLoaded</th>
                                    <th scope="col">Actions</th>
                                    <th scope="col">Orders</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentPlans.map((plan, index) => (
                                    <React.Fragment key={plan.deliveryPlanId}>
                                        <tr>
                                            <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                                            <td>{plan.vehicleRegistrationNumber}</td>
                                            <td>{plan.driverName}</td>
                                            <td>{formatDate(plan.dateCreated)}</td>
                                            <td>
                                                <span
                                                    className={`badge rounded-pill px-2 py-1 ${plan.deliveryStatusName === 'New Plan'
                                                        ? 'bg-primary text-white'
                                                        : plan.deliveryStatusName === 'Assigned Orders'
                                                            ? 'bg-info text-white'
                                                            : plan.deliveryStatusName === 'Inventory Loaded'
                                                                ? 'bg-warning text-white'
                                                                : plan.deliveryStatusName === 'Loaded'
                                                                    ? 'bg-success text-white'
                                                                    : plan.deliveryStatusName === 'Completed'
                                                                        ? 'bg-success text-white'
                                                                        : 'bg-danger text-white'
                                                        }`}
                                                >
                                                    {plan.deliveryStatusName}
                                                </span>
                                            </td>
                                            <td>{plan.loadedQuantity}</td>
                                            <td>{plan.offLoadedQuantity}</td>
                                            <td>{formatDate(plan.dateLoaded)}</td>
                                            <td>{formatDate(plan.dateOffLoaded)}</td>
                                            <td>
                                                <div className="dropdown">
                                                    <button
                                                        className="btn btn-light btn-sm dropdown-toggle"
                                                        type="button"
                                                        id="dropdownMenuButton1"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        Actions
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        {plan.deliveryStatusName !== 'New Plan' &&
                                                            plan.deliveryStatusName !== 'Completed' &&
                                                            plan.deliveryStatusName !== 'Cancelled' &&
                                                            plan.deliveryStatusName !== 'Loaded' && (
                                                                <li>
                                                                    <Link
                                                                        className="dropdown-item"
                                                                        onClick={() => handleShowLoadPlan(plan.deliveryPlanId)}
                                                                    >
                                                                        Load
                                                                    </Link>
                                                                </li>
                                                            )}

                                                        {plan.deliveryStatusName !== 'New Plan' &&
                                                            plan.deliveryStatusName !== 'Completed' &&
                                                            plan.deliveryStatusName !== 'Cancelled' &&
                                                            plan.deliveryStatusName !== 'Assigned Orders' && (
                                                                <li>
                                                                    <Link
                                                                        className="dropdown-item"
                                                                        onClick={() => handleShowOffLoadPlan(plan.deliveryPlanId)}
                                                                    >
                                                                        OffLoad
                                                                    </Link>
                                                                </li>
                                                            )}

                                                        <li>
                                                            <Link
                                                                className="dropdown-item"
                                                                onClick={() => handleShowAttachOrder(plan.deliveryPlanId)}
                                                            >
                                                                Attach Order
                                                            </Link>
                                                        </li>

                                                        {plan.deliveryStatusName !== 'Completed' &&
                                                            plan.deliveryStatusName !== 'Cancelled' && (
                                                                <li>
                                                                    <Link
                                                                        className="dropdown-item"
                                                                        onClick={() => handleShowCancelDeliveryPlan(plan.deliveryPlanId)}
                                                                    >
                                                                        Cancel Plan
                                                                    </Link>
                                                                </li>
                                                            )}

                                                        {(plan.deliveryStatusName === 'Assigned Orders' ||
                                                            plan.deliveryStatusName === 'Cancelled') && (
                                                                <li>
                                                                    <Link
                                                                        className="dropdown-item"
                                                                        onClick={() => handleShowDetachOrder(plan)}
                                                                    >
                                                                        Detach Orders
                                                                    </Link>
                                                                </li>
                                                            )}

                                                        {plan.deliveryStatusName !== 'Completed' &&
                                                            plan.deliveryStatusName !== 'New Order' &&
                                                            plan.deliveryStatusName !== 'Cancelled' && (
                                                                <li>
                                                                    <Link
                                                                        className="dropdown-item"
                                                                        onClick={() =>
                                                                            handleShowPatchDeliveryPlan(plan.deliveryPlanId, plan.driverId, plan.vehicleId)
                                                                        }
                                                                    >
                                                                        Reassign Driver/Vehicle
                                                                    </Link>
                                                                </li>
                                                            )}

                                                        {plan.deliveryStatusName === 'Completed' && (
                                                            <li>
                                                                <Link
                                                                    className="dropdown-item"
                                                                    onClick={() => { }}
                                                                >
                                                                    Push orders to SAP
                                                                </Link>
                                                            </li>
                                                        )}
                                                        <li>
                                                            <Link
                                                                className="dropdown-item"
                                                                onClick={() => handleShowViewDeliveryPlan(plan.deliveryPlanId)}
                                                            >
                                                                View Delivery Note
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                            <td>
                                                {plan.orders && plan.orders.length > 0 && (
                                                    <button
                                                        className="btn btn-primary btn-sm dropdown-toggle"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#collapseOrders${plan.deliveryPlanId}`}
                                                        aria-expanded="false"
                                                        aria-controls={`collapseOrders${plan.deliveryPlanId}`}
                                                    >
                                                        View Orders
                                                    </button>
                                                )}
                                            </td>

                                        </tr>
                                        {plan.orders && plan.orders.length > 0 && (
                                            <tr id={`collapseOrders${plan.deliveryPlanId}`} className="collapse">
                                                <td colSpan="11">
                                                    <table className="table table-sm">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Order ID</th>
                                                                <th scope="col">Customer Name</th>
                                                                <th scope="col">Location</th>
                                                                <th scope="col">Ordered Quantity</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {plan.orders.map((order) => (
                                                                <tr key={order.orderId}>
                                                                    <td>{order.orderId}</td>
                                                                    <td>{order.customerName}</td>
                                                                    <td>{order.locationName}</td>
                                                                    <td>{order.orderedQuantity}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>


                        <div className="d-flex justify-content-between align-items-center">
                            <span>
                                Showing {indexOfFirstPlan + 1} to {Math.min(indexOfLastPlan, filteredPlans.length)} of{' '}
                                {totalRecords} entries
                            </span>
                            <nav>
                                <ul className="pagination">
                                    {Array.from({ length: totalPages }, (_, i) => (
                                        <li key={i} className={`page-item ${i + 1 === currentPage ? 'active' : ''}`}>
                                            <Link className="page-link" onClick={() => paginate(i + 1)}>
                                                {i + 1}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </Main>
        </div>
    );
};

export default ListDeliveryPlans;
