// Importing Libraries
import React from "react";
import { useNavigate } from "react-router-dom";
import {Link} from 'react-router-dom';




function Sidebar({page}) {

  let urlEmpty = '';



  // Function Logout

  return (
    <React.Fragment>
      <div className="niceadmin">
         <aside id="sidebar" className="sidebar">

    <ul className="sidebar-nav" id="sidebar-nav">

      <li className="nav-item">
        <Link className="nav-link " to="/">
          <i className="bi bi-grid"></i>
          <span>Dashboard</span>
        </Link>
      </li> {/* End Dashboard Nav */}


     
    <li className="nav-item">
      <Link className="nav-link " to="/bulk/customers">
      <i className="bi bi-person"></i>
        <span>Customers</span>
      </Link>
    </li> {/* End Dashboard Nav */}

   

    <li className="nav-item">
    <Link className="nav-link " to="/bulk/locations">
    <i className="bi bi-person"></i>
      <span>Customer Locations</span>
    </Link>
  </li> 

    <li className="nav-item">
      <Link className="nav-link " to="/bulk/vehicles">
      <i className="bi bi-truck"></i>
        <span>Vehicles</span>
      </Link>
    </li> {/* End Dashboard Nav */}

    <li className="nav-item">
      <Link className="nav-link " to="/bulk/orders">
      <i className="bi bi-coin"></i>
        <span>Orders</span>
      </Link>
    </li> {/* End Dashboard Nav */}


    {/* <li className="nav-item">
      <Link className="nav-link " to="/bulk/users">
      <i className="bi bi-person-circle"></i>
        <span>User Management</span>
      </Link>
    </li> End Dashboard Nav */}
    


    <li className="nav-item">
      <a className="nav-link collapsed" data-bs-target="#staff-management-nav" data-bs-toggle="collapse" href="#">
        <i className="bi bi-layout-text-window-reverse"></i><span>Staff Management</span><i className="bi bi-chevron-down ms-auto"></i>
      </a>
      <ul id="staff-management-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
        <li>
          <a href="/users">
            <i className="bi bi-circle-fill"></i><span> Users</span>
          </a>
        </li>
        <li>
          <a href="/users/roles">
            <i className="bi bi-circle-fill"></i><span>Roles</span>
          </a>
        </li>

        <li>
        <a href="/users/profile">
          <i className="bi bi-circle-fill"></i><span>Profile</span>
        </a>
      </li>

        
      </ul>
    </li> {/* End Tables Nav */}

    <li className="nav-item">
    <a className="nav-link collapsed" data-bs-target="#drivers-nav" data-bs-toggle="collapse" href="#">
      <i className="bi bi-layout-text-window-reverse"></i><span>Drivers</span><i className="bi bi-chevron-down ms-auto"></i>
    </a>
    <ul id="drivers-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">

      <li>
        <a href="/bulk/drivers">
          <i className="bi bi-circle-fill"></i><span>Drivers</span>
        </a>
      </li>
    </ul>
  </li> {/* End Tables Nav */}


    <li className="nav-item">
    <a className="nav-link collapsed" data-bs-target="#tripplanning-nav" data-bs-toggle="collapse" href="#">
      <i className="bi bi-layout-text-window-reverse"></i><span>Delivery Plans</span><i className="bi bi-chevron-down ms-auto"></i>
    </a>
    <ul id="tripplanning-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">

      <li>
        <a href="/bulk/delivery-plans">
          <i className="bi bi-circle-fill"></i><span>Delivery Plans</span>
        </a>
      </li>
    </ul>
  </li> {/* End Tables Nav */}

   


    </ul>

  </aside> {/* End Sidebar*/}
      </div>
    </React.Fragment>
  );
}

export default Sidebar;