import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/users/userContext";
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported

const ResetPassword = () => {
    const userContext = useContext(UserContext);
    const { resetPassword } = userContext;
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
        resetPassword(data);
        navigate('/login'); // Redirect to a success page or another appropriate route
    };

    const watchNewPassword = watch('newpassword');
    const watchConfirmPassword = watch('ConformPassword');

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title mb-4">Change Password</h5>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="mb-3">
                                    <label htmlFor="newpassword" className="form-label">New Password</label>
                                    <input
                                        type={watchNewPassword && watchNewPassword.showPassword ? "text" : "password"}
                                        className="form-control"
                                        id="newpassword"
                                        {...register('newpassword', {
                                            required: "New password is required",
                                            minLength: { value: 6, message: "Password must be at least 6 characters" }
                                        })}
                                    />
                                    <span
                                        onClick={() => watchNewPassword && (watchNewPassword.showPassword = !watchNewPassword.showPassword)}
                                        className="password-icon"
                                    >
                                        <i className={`fa ${watchNewPassword && watchNewPassword.showPassword ? "fa-eye-slash" : "fa-eye"}`}></i>
                                    </span>
                                    {errors.newpassword && <div className="text-danger">{errors.newpassword.message}</div>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="ConformPassword" className="form-label">Confirm Password</label>
                                    <input
                                        type={watchConfirmPassword && watchConfirmPassword.showConformPassword ? "text" : "password"}
                                        className="form-control"
                                        id="ConformPassword"
                                        {...register('ConformPassword', {
                                            required: "Please confirm your password",
                                            validate: value =>
                                                value === watchNewPassword || "Passwords do not match"
                                        })}
                                    />
                                    <span
                                        onClick={() => watchConfirmPassword && (watchConfirmPassword.showConformPassword = !watchConfirmPassword.showConformPassword)}
                                        className="password-icon"
                                    >
                                        <i className={`fa ${watchConfirmPassword && watchConfirmPassword.showConformPassword ? "fa-eye-slash" : "fa-eye"}`}></i>
                                    </span>
                                    {errors.ConformPassword && <div className="text-danger">{errors.ConformPassword.message}</div>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="Otp" className="form-label">OTP</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="Otp"
                                        {...register('Otp', { required: "OTP is required" })}
                                    />
                                    {errors.Otp && <div className="text-danger">{errors.Otp.message}</div>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="phoneNumber"
                                        {...register('phoneNumber', { required: "Phone number is required" })}
                                    />
                                    {errors.phoneNumber && <div className="text-danger">{errors.phoneNumber.message}</div>}
                                </div>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
