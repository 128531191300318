import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import CustomerContext from '../../context/bulk/customers/customersContext';
import OrderContext from '../../context/bulk/orders/ordersContext';

const PatchDeliveryPlanModal = ({ show, handleClose, deliveryPlanId, initialDriverId, initialVehicleId }) => {
    const customerContext = useContext(CustomerContext);
    const { drivers, vehicles, listDrivers, listVehicles } = customerContext;
    const orderContext = useContext(OrderContext);
    const { patchDeliveryPlan } = orderContext;

    const [selectedDriver, setSelectedDriver] = useState(null);
    const [selectedVehicle, setSelectedVehicle] = useState(null);

    useEffect(() => {
        if (!drivers.length) listDrivers();
        if (!vehicles.length) listVehicles();
    }, [drivers, vehicles, listDrivers, listVehicles]);

    useEffect(() => {
        if (drivers.length && initialDriverId) {
            const driver = drivers.find(d => d.userCode === initialDriverId);
            setSelectedDriver(driver ? { value: driver.userCode, label: driver.names } : null);
        }
        if (vehicles.length && initialVehicleId) {
            const vehicle = vehicles.find(v => v.vehicleId === initialVehicleId);
            setSelectedVehicle(vehicle ? { value: vehicle.vehicleId, label: vehicle.registrationNumber } : null);
        }
    }, [drivers, vehicles, initialDriverId, initialVehicleId]);

    const handlePatch = async () => {
        if (selectedDriver && selectedVehicle) {
            await patchDeliveryPlan(deliveryPlanId, selectedDriver.value, selectedVehicle.value);
            setSelectedDriver(null);
            setSelectedVehicle(null);
            handleClose();
        }
    };

    const driverOptions = drivers.map(driver => ({ value: driver.userCode, label: driver.names }));
    const vehicleOptions = vehicles.map(vehicle => ({ value: vehicle.vehicleId, label: vehicle.registrationNumber }));

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Reassign Delivery Plan</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formDriver">
                        <Form.Label>Driver</Form.Label>
                        <Select
                            options={driverOptions}
                            value={selectedDriver}
                            onChange={setSelectedDriver}
                            placeholder="Select Driver"
                        />
                    </Form.Group>
                    <Form.Group controlId="formVehicle">
                        <Form.Label>Vehicle</Form.Label>
                        <Select
                            options={vehicleOptions}
                            value={selectedVehicle}
                            onChange={setSelectedVehicle}
                            placeholder="Select Vehicle"
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handlePatch}>
                    Update
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PatchDeliveryPlanModal;
