import React, { useReducer } from "react";
import {  GetUsers, Register, UpdateUser, ResetPassword, ActivateUser, DeactivateUser, SendOTP, assignUserApps } from "../../api/auth";
// import { handleErrorNotification } from "../../api/util";
import UserContext from "./userContext";
import UserReducer from "./userReducer";


import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    GET_USERS,
    CLEAR_NOTIFICATION
} from "../types";

const UserState = props => {
    const initialState = {
        users: [],
        selected_user_code: "",
        notification: null,
    };

    const [state, dispatch] = useReducer(UserReducer, initialState);



    // Register User    
    const register = async formData => {
        let response = await Register(formData);
        if (response && response.responseCode === 1) {
            dispatch({
                type: REGISTER_SUCCESS,
                payload: response.responseMessage

            })
        }
        else {
            dispatch({
                type: REGISTER_FAIL,
                payload: response
            });

        }
    };



    const fetchUsers = async () => {
        let users = await GetUsers();
        if (users.responseCode === 1) {
            dispatch({ type: GET_USERS, payload: users.responseObject });
        }

    }

    const resetPassword = async formData => {
        let response = await ResetPassword(formData);
        if (response && response.responseCode === 1) {
            dispatch({
                type: REGISTER_SUCCESS,
                payload: response.responseMessage
            })
        }
        else {
            dispatch({
                type: REGISTER_FAIL,
                payload: response.responseMessage
            });

        }
    }

    const activateUser = async userCode => {
        let response = await ActivateUser(userCode);
        if (response && response.responseCode === 1) {
            dispatch({
                type: REGISTER_SUCCESS,
                payload: response.responseMessage

            })
        }
        else {
            dispatch({
                type: REGISTER_FAIL,
                payload: response.responseMessage
            });
        }
    }

    const deactivateUser = async userCode => {
        let response = await DeactivateUser(userCode);
        if (response && response.responseCode === 1) {
            dispatch({
                type: REGISTER_SUCCESS,
                payload: response.responseMessage

            })
        }
        else {
            dispatch({
                type: REGISTER_FAIL,
                payload: response.responseMessage
            });
        }
    }

    const clear_notifications = () => {
        dispatch({ type: CLEAR_NOTIFICATION });
    }

    const requestOtp = async (phoneNumber) => {
        let response = await SendOTP(phoneNumber);
        if (response && response.responseCode === 1) {
            dispatch({
                type: REGISTER_SUCCESS,
                payload: response.responseMessage

            })
        }
        else {
            dispatch({
                type: REGISTER_FAIL,
                payload: response.responseMessage
            });
        }
    }

    const assignUserApp = async (userId, appId) => {
        let response = await assignUserApps(userId,appId);

        if (response && response.responseCode === 1) {
            dispatch({
                type: REGISTER_SUCCESS,
                payload: response.responseMessage

            })
        }
        else {
            dispatch({
                type: REGISTER_FAIL,
                payload: response.responseMessage
            });
        }

    };

    const updateUser =  async (userCode,formData) => {
        let response = await UpdateUser(userCode,formData);
        if (response && response.responseCode === 1) {
            dispatch({
                type: REGISTER_SUCCESS,
                payload: response.responseMessage

            })
        }
        else {
            dispatch({
                type: REGISTER_FAIL,
                payload: response.responseMessage
            });

        }
    }





    return (
        <UserContext.Provider
            value={{


                error: state.error,
                users: state.users,
                notification: state.notification,
                selected_user_code: state.selected_user_code,
                // clearErrors,
                fetchUsers,
                register,
                resetPassword,
                activateUser,
                deactivateUser,
                clear_notifications,
                requestOtp,
                assignUserApp,
                updateUser
            }}
        >
            {props.children}
        </UserContext.Provider>
    );

}

export default UserState;