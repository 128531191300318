import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import CustomersContext from "../../context/bulk/customers/customersContext";
import { useNavigate } from "react-router-dom";
import SweetAlertWrapper from "../../components/Layout/SweetAlert";

const AddCustomerLocation = ({ show, handleClose, customerId }) => {
    const navigate = useNavigate();
    const customerContext = useContext(CustomersContext);
    const { addCustomerLocation, notification, clear_notifications } = customerContext;

    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        if (notification) {
            setAlertMessage({ text: notification.text, type: notification.type });
            setShowAlert(true);  // Set alert to show immediately when notification is received
        }
    }, [notification]);

    const [location, setLocation] = useState({
        customerId: "",
        locationName: "",
        latitude: 0,
        longitude: 0
    });

    const onChange = (e) => {
        const { name, value } = e.target;
        setLocation({
            ...location,
            [name]: value
        });
    };

    const handleConfirm = () => {
        setShowAlert(false);
        clear_notifications();
        handleClose();
    };

    useEffect(() => {
        if (customerId) {
            setLocation({
                ...location,
                customerId: customerId
            });
        } else {
            navigate('/bulk/customers');
        }
    }, [customerId]);

    const onSubmit = (e) => {
        e.preventDefault();
        addCustomerLocation(location);
    };

    // // Reset state when the modal closes
    // useEffect(() => {
    //     if (!show) {
    //         setAlertMessage("");
    //         setShowAlert(false);
    //     }
    // }, [show]);

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Customer Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SweetAlertWrapper
                        show={showAlert}
                        title="Notification"
                        message={alertMessage.text}
                        onConfirm={handleConfirm}
                        confirmBtnText="OK"
                        type={alertMessage.type}
                    />
                    <Form onSubmit={onSubmit}>
                        <Form.Group controlId="locationName">
                            <Form.Label>Location Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="locationName"
                                value={location.locationName}
                                onChange={onChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="latitude">
                            <Form.Label>Latitude</Form.Label>
                            <Form.Control
                                type="text"
                                name="latitude"
                                value={location.latitude}
                                onChange={onChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="longitude">
                            <Form.Label>Longitude</Form.Label>
                            <Form.Control
                                type="text"
                                name="longitude"
                                value={location.longitude}
                                onChange={onChange}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddCustomerLocation;
