import React, { useReducer } from "react";
import OrderContext from "./ordersContext";
import OrderReducer from "./ordersReducer";
import {
    create_order, list_orders, deliverorder,
    remove_order, cancel_delivery_plan, list_products,
    list_delivery_plans, driver_orders,
    new_loading, add_plan, get_dashboard_data,
    offloading, attach_order, patch_delivery_plan,
    manager_approval, finance_approval, list_unassigned_orders
} from '../../../api/bulk'
import { handleErrorNotification } from "../../../api/util";

import {
    ADD_ORDER,
    DELETE_ORDER,
    SET_CURRENT,
    CLEAR_CURRENT,
    UPDATE_ORDER,
    FILTER_ORDERS,
    CLEAR_FILTER,
    ORDER_ERROR,
    GET_ORDERS,
    CLEAR_ORDERS,
    CLEAR_NOTIFICATION,
    LIST_PRODUCTS,
    LIST_DELIVERY_PLANS,
    ATTACH_ORDER,
    OFFLOAD_ORDER,
    GET_DASHBOARD_DATA,
    GET_UNASSIGNED_ORDERS
} from "../../types";

const OrdersState = props => {
    const initialState = {
        orders: [],
        current: null,
        filtered: null,
        error: null,
        products: [],
        delivery_plans: [],
        totalRecords: 0,
        notifications: null,
        dashBoardData: {},
        unassignedOrders: []
    };

    const [state, dispatch] = useReducer(OrderReducer, initialState);

    const getOrders = async () => {
        const res = await list_orders();
        if (res.responseCode === 1) {
            dispatch({
                type: GET_ORDERS,
                payload: res.responseObject
            });
        }

    };

    // Add Contact
    const addOrder = async order => {
        const res = await create_order(order);
        if (res.responseCode === 1) {
            dispatch({
                type: ADD_ORDER,
                payload: res.responseMessage
            });
        }
        else {
            dispatch({
                type: ORDER_ERROR,
                payload: res.responseMessage
            });
        }
    };

    const getUnassignedOrders = async () => {
        const res = await list_unassigned_orders();
        console.log(res.responseObject, "res")
        if (res.responseCode === 1) {
            dispatch({
                type: GET_UNASSIGNED_ORDERS,
                payload: res.responseObject
            });
        }
    }

    // Cancel Plan
    const cancelPlan = async order => {
        const res = await cancel_delivery_plan(order);
        if (res.responseCode === 1) {
            dispatch({
                type: ADD_ORDER,
                payload: res.responseMessage
            });
        }
        else {
            dispatch({
                type: ORDER_ERROR,
                payload: res.responseMessage
            });
        }
    }

    const addPlan = async order => {
        const res = await add_plan(order);
        if (res.responseCode === 1) {
            dispatch({
                type: ADD_ORDER,
                payload: res.responseMessage
            });
        }
        else {
            dispatch({
                type: ORDER_ERROR,
                payload: res.responseMessage
            });
        }
    }

    const listProducts = async () => {
        const res = await list_products();
        if (res.responseCode === 1) {
            dispatch({ type: LIST_PRODUCTS, payload: res.responseObject });
        }

    }

    const listDeliveryPlans = async (pageNumber, pageSize) => {
        const res = await list_delivery_plans(pageNumber, pageSize);
        if (res.responseCode === 1) {
            dispatch({ type: LIST_DELIVERY_PLANS, payload: res.responseObject });
        }
    }

    const loadOrder = async order => {
        const res = await new_loading(order);
        if (res.responseCode === 1) {
            dispatch({
                type: ADD_ORDER,
                payload: res.responseMessage
            });
        }
        else {
            dispatch({
                type: ORDER_ERROR,
                payload: res.responseMessage
            });
        }
    }

    const offloadOrder = async order => {
        const res = await offloading(order);
        if (res.responseCode === 1) {
            dispatch({
                type: OFFLOAD_ORDER,
                payload: res.responseMessage
            });
        }
        else {
            dispatch({
                type: ORDER_ERROR,
                payload: res.responseMessage
            });
        }
    }


    const attachOrder = async order => {
        const res = await attach_order(order);
        if (res.responseCode === 1) {
            dispatch({
                type: ATTACH_ORDER,
                payload: res.responseMessage
            })
        }
        else {
            dispatch({
                type: ORDER_ERROR,
                payload: res.responseMessage
            });
        }
    }



    const deliverOrder = async order => {
        const res = await deliverorder(order);
        if (res.responseCode === 1) {
            dispatch({
                type: ADD_ORDER,
                payload: res.responseMessage
            });
        }
        else {
            dispatch({
                type: ORDER_ERROR,
                payload: res.responseMessage
            });
        }
    }

    const removeOrder = async (planId, orderIds) => {
        const res = await remove_order(planId, orderIds);
        if (res.responseCode === 1) {
            dispatch({
                type: ADD_ORDER,
                payload: res.responseMessage
            });
        }
        else {
            dispatch({
                type: ORDER_ERROR,
                payload: res.responseMessage
            });
        }
    }


    // Set Current Contact
    const setCurrent = order => {
        dispatch({ type: SET_CURRENT, payload: order });
    };

    // Clear Current Contact
    const clearCurrent = () => {
        dispatch({ type: CLEAR_CURRENT });
    };

    // Update Contact
    const updateOrder = async order => {

    };

    // Filter orders
    const filterorders = text => {
        dispatch({ type: FILTER_ORDERS, payload: text });
    };

    // Clear Filter
    const clearFilter = () => {
        dispatch({ type: CLEAR_FILTER });
    };
    // Clear orders
    const clearorders = () => {
        dispatch({ type: CLEAR_ORDERS });
    };

    const clear_notification = () => {
        dispatch({ type: CLEAR_NOTIFICATION });
    }

    const getDashboardData = async () => {
        const res = await get_dashboard_data()
        if (res.responseCode === 1) {
            dispatch({
                type: GET_DASHBOARD_DATA,
                payload: res.responseObject
            });
        }
    }

    const managerApproval = async order => {
        const res = await manager_approval(order)
        if (res.responseCode === 1) {
            dispatch({
                type: ADD_ORDER,
                payload: res.responseMessage
            })
        }
        else {
            dispatch({
                type: ORDER_ERROR,
                payload: res.responseMessage
            });
        }
    }

    const financeApproval = async order => {
        const res = await finance_approval(order)
        if (res.responseCode === 1) {
            dispatch({
                type: ADD_ORDER,
                payload: res.responseMessage
            })
        }
        else {
            dispatch({
                type: ORDER_ERROR,
                payload: res.responseMessage
            });
        }
    }

    const patchDeliveryPlan = async (planId, driverId, vehicleId) => {
        const res = await patch_delivery_plan(planId, driverId, vehicleId);
        console.log(res, "res")
        if (res.responseCode === 1) {
            dispatch({
                type: ADD_ORDER,
                payload: res.responseMessage
            });
        }
        else {
            dispatch({
                type: ORDER_ERROR,
                payload: res.responseMessage
            });
        }
    }

    return (
        <OrderContext.Provider
            value={{
                orders: state.orders,
                current: state.current,
                filtered: state.filtered,
                error: state.error,
                products: state.products,
                notification: state.notification,
                delivery_plans: state.delivery_plans,
                dashBoardData: state.dashBoardData,
                unassignedOrders: state.unassignedOrders,
                totalRecords: state.totalRecords,
                addOrder,
                getOrders,
                setCurrent,
                loadOrder,
                offloadOrder,
                clearCurrent,
                updateOrder,
                filterorders,
                clearFilter,
                clearorders,
                clear_notification,
                cancelPlan,
                deliverOrder,
                removeOrder,
                listProducts,
                listDeliveryPlans,
                addPlan,
                attachOrder,
                offloadOrder,
                getDashboardData,
                managerApproval,
                financeApproval,
                getUnassignedOrders,
                patchDeliveryPlan
            }}
        >
            {props.children}
        </OrderContext.Provider>
    );
};

export default OrdersState;