import {
    ADD_CUSTOMER,
    UPDATE_CUSTOMER,
    CUSTOMER_ERROR,
    GET_CUSTOMERS,
    SEARCH_CUSTOMER,
    CLEAR_NOTIFICATION,
    LIST_VEHICLES,
    LIST_DRIVERS,
    SEARCH_VEHICLE,
    UPDATE_VEHICLE,
    GET_CUSTOMERS_LOCATIONS,
    ADD_CUSTOMER_LOCATION


} from "../../types"

export default (state, action) => {
    switch (action.type) {
        case GET_CUSTOMERS:
            return {
                ...state,
                customers: action.payload,
                loading: false
            };

        case SEARCH_CUSTOMER:
            return {
                ...state,
                customers: action.payload,
                loading: false
            };

        case ADD_CUSTOMER:
            return {
                ...state,
                notification: {
                    type: "success",
                    text: action.payload
                }
            };
        case ADD_CUSTOMER_LOCATION:
            return {
                ...state,
                notification: {
                    type: "success",
                    text: action.payload
                }
            }

        case UPDATE_CUSTOMER:
            return {
                ...state,
                loading: false,
                notification: {
                    type: "success",
                    text: action.payload
                }
            };
        case CUSTOMER_ERROR:
            return {
                ...state,
                notification: {
                    type: "error",
                    text: action.payload
                }
            }
        case CLEAR_NOTIFICATION:
            return {
                ...state,
                notification: null
            };

        case LIST_VEHICLES:
            return {
                ...state,
                vehicles: action.payload
            };
        case LIST_DRIVERS:
            return {
                ...state,
                drivers: action.payload
            };
        case SEARCH_VEHICLE:
            return {
                ...state,
                current_vehicle: action.payload
            };
        case UPDATE_VEHICLE:
            return {
                ...state,
                notification: {
                    type: "success",
                    text: action.payload
                }
            }
        case GET_CUSTOMERS_LOCATIONS:
            return {
                ...state,
                customer_locations: action.payload
            }
        default:
            return state;
    }


}



