import axios from './index';

// lists
export const list_customers = async () => {
    try {
        const res = await axios.get('/bulk/Bulk/Customers');
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const list_customer_locations = async () => {
    try {
        const res = await axios.get('/bulk/Bulk/CustomersList');
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const list_products = async () => {
    try {
        const res = await axios.get('/bulk/Bulk/Products');
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const list_orders = async () => {
    try {
        const res = await axios.get('/bulk/Bulk/BulkOrders');
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const list_unassigned_orders = async () => {
    try {
        const res = await axios.get('/bulk/Bulk/UnassignedOrders');
        console.log(res, "res")
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}


export const agent_orders = async (formData) => {
    try {
        const res = await axios.get('/bulk/Bulk/AgentOrders');
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}


export const driver_orders = async (formData) => {
    try {
        const res = await axios.get('/bulk/Bulk/DriverOrders');
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

// list_drivers
export const list_drivers = async () => {
    try {
        const res = await axios.get('/bulk/Bulk/BulkDrivers');
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const list_delivery_plans = async (pageNumber, pageSize) => {
    // pageNumber,pageSize

    try {
        const res = await axios.get(`/bulk/Bulk/DeliveryPlans?pageNumber=${pageNumber}&pageSize=${pageSize}`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}


// customer

export const create_customer = async (formData) => {
    try {
        const res = await axios.post('/bulk/Bulk/AddCustomer', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const update_customer_status = async (customer_id, status) => {
    try {
        const res = await axios.post(`/bulk/Bulk/UpdateCustomerStatus?customerId=${customer_id}&IsActive=${status}`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const update_customer_info = async (customerId, formData) => {
    try {
        const res = await axios.post(`/bulk/Bulk/UpdateCustomerInfo?customerId=${customerId}`, formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const search_customer = async (customer_name) => {
    try {
        const res = await axios.post(`/bulk/Bulk/SearchCustomer?customerName=${customer_name}`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const add_customer_location = async (formData) => {
    console.log(formData, "formData")
    try {
        const res = await axios.post('/bulk/Bulk/AddCustomerLocation', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const update_customer_location = async (formData) => {
    try {
        const res = await axios.post('/bulk/Bulk/UpdateCustomerLocation', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}



// vehicle
export const add_vehicle = async (formData) => {
    try {
        const res = await axios.post('/bulk/Bulk/AddVehicle', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

// list vehicles
export const list_vehicles = async () => {
    try {
        const res = await axios.get('/bulk/Bulk/BulkVehicleListing');
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const update_vehicle = async (formData) => {
    try {
        const res = await axios.post('/bulk/Bulk/UpdateVehicle', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const search_vehicle_by_reg_no = async (reg_no) => {
    try {
        const res = await axios.post(`/bulk/Bulk/SearchVehicleByRegNumber?vehicleRegistrationNumner=${reg_no}`);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}


// orders

export const create_order = async (formData) => {
    try {
        const res = await axios.post('/bulk/Bulk/AddOrder', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const add_plan = async (formData) => {
    // bulk/Bulk/AddPlan?vehicleId=0000009&driverId=00004
    try {
        const res = await axios.post(`/bulk/Bulk/AddPlan?vehicleId=${formData.vehicleId}&driverId=${formData.driverId}`)
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const cancel_delivery_plan = async (deliveryPlanId) => {
    try {
        const res = await axios.post(`/bulk/Bulk/CancelDeliveryPlan?deliveryPlanId=${deliveryPlanId}`)
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}


export const patch_delivery_plan = async (planId,driverId,vehicleId ) => {
    try {
        const res = await axios.put(`/bulk/Bulk/PlanAssignMents?deliveryPlanId=${planId}&driverId=${driverId}&vehicleId=${vehicleId}`)
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const attach_order = async (formData) => {
    console.log(formData, "formData")
    try {
        const res = await axios.post("/bulk/Bulk/AttachOrder", formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

// loading
export const new_loading = async (formData) => {
    console.log(formData, "formData")

    try {
        const res = await axios.post('/bulk/Bulk/Loading', formData);
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const offloading = async (formData) => {
    try {
        const res = await axios.post('/bulk/Bulk/Offloading', formData)
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const deliverorder = async (formData) => {
    try {
        console.log(formData, "formData")
        const res = await axios.post('/bulk/Bulk/DeliverOrders', formData);
        console.log(res, "res")
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}


export const remove_order = async (planId, orderIds) => {
    console.log(orderIds, "SIIIIUUUUUU");
    try {
        // Make the DELETE request with the orderIds in the body
        const res = await axios.delete(`/bulk/Bulk/RemoveOrder?deliveryPlanId=${planId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*'
            },
            data: JSON.stringify(orderIds)  // Stringify the orderIds
        });
        console.log(res, "res")
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
};



// approvals
export const finance_approval = async (formData) => {
    console.log(formData, "formData")
    try {
        const res = await axios.post(`/bulk/Bulk/FinanceApproval?orderId=${formData}`)
        console.log(res, "res")
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

// manager approval
export const manager_approval = async (formData) => {
    console.log(formData, "formData")
    try {
        const res = await axios.post(`/bulk/Bulk/ManagerApproval?orderId=${formData}`)
        console.log(res, "res")
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}

export const get_dashboard_data = async () => {
    try {
        const res = await axios.get('/bulk/Bulk/WebDashBoard');
        return res.data;
    }
    catch (error) {
        return error.response.data;
    }
}


