import React, {useContext, useEffect, useState} from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import OrderContext from "../../context/bulk/orders/ordersContext";
import SweetAlertWrapper from '../../components/Layout/SweetAlert';

const ApproveOrderModal = ({ show, handleClose, orderId, type }) => {
    const orderContext = useContext(OrderContext);
    const  {managerApproval, financeApproval} = orderContext;

    const handleApprove = () => {
        if(type === 'manager'){
            managerApproval(orderId)
        }else{
            financeApproval(orderId)
        }
    }


    // add a are you sure message

    return (
        <div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Approve Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to approve this order?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleApprove}>
                        Approve Order
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

}


export default ApproveOrderModal;


