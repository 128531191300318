import React, { useState, useContext, useEffect } from "react";
import Header from "../../components/Layout/Header";
import Main from "../../components/Layout/Main";
import Sidebar from "../../components/Layout/Sidebar";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import AlertContext from "../../context/alerts/alertContext";
import Alerts from "../../components/Layout/Alerts";
import SweetAlertWrapper from "../../components/Layout/SweetAlert";
import OrderContext from "../../context/bulk/orders/ordersContext";
import CustomersContext from "../../context/bulk/customers/customersContext";

const AddOrders = () => {
    const navigate = useNavigate();
    const page = 'Add Order';

    const alertContext = useContext(AlertContext);
    const { setAlert } = alertContext;

    const orderContext = useContext(OrderContext);
    const { addOrder, notification, clear_notifications, products, listProducts } = orderContext;

    const customersContext = useContext(CustomersContext);
    const { customer_locations, getCustomerLocations } = customersContext;

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });

    const { handleSubmit, register, formState: { errors, isValid }, setValue } = useForm({
        mode: 'onChange',
    });

    useEffect(() => {
        getCustomerLocations();
        listProducts(); // Fetch products when component mounts
    }, []);

    useEffect(() => {
        if (notification) {
            setAlertMessage({ text: notification.text, type: notification.type });
            setShowAlert(true);
        }
    }, [notification]);

    const handleFormSubmit = (data) => {
        addOrder(data);
        navigate('/bulk/orders');

    };

    const handleConfirm = () => {
        setShowAlert(false);
        if (notification.type === "success") {
            navigate('/bulk/orders');
        }
        clear_notifications();
    };

    const customerOptions = customer_locations.map(customer => ({
        value: customer.customerLocationId,
        label: `${customer.customerName} - ${customer.locationName}`
    }));

    const productOptions = products.map(product => ({
        value: product.id,
        label: product.productName
    }));

    console.log(productOptions, 'productOptions');

    const handleCustomerChange = (selectedOption) => {
        setValue('customerLocationId', selectedOption.value);
    };

    const handleProductChange = (selectedOption) => {
        setValue('productId', selectedOption.value);
    };

    return (
        <div className="niceadmin">
            <Header />
            <Sidebar page={page} />
            <Main>
                <SweetAlertWrapper
                    show={showAlert}
                    title="Success"
                    message={alertMessage.text}
                    onConfirm={handleConfirm}
                    confirmBtnText="OK"
                    type={alertMessage.type}
                />
                <Alerts />
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <div className="align-center">
                                            <h5 className="card-title">Add Orders</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-4"></div>
                                </div>
                                <form className="needs-validation" onSubmit={handleSubmit(handleFormSubmit)} noValidate>
                                    <div className="row mb-3">
                                        <label htmlFor="customerLocationId" className="col-sm-3 col-form-label">Customer Location</label>
                                        <div className="col-sm-9">
                                            <Select
                                                options={customerOptions}
                                                onChange={handleCustomerChange}
                                                classNamePrefix="react-select"
                                                name="customerLocationId"
                                                id="customerLocationId"
                                                placeholder="Select Customer Location"
                                                isSearchable
                                            />
                                            {errors.customerLocationId && <div className="invalid-feedback">{errors.customerLocationId.message}</div>}
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label htmlFor="productId" className="col-sm-3 col-form-label">Product</label>
                                        <div className="col-sm-9">
                                            <Select
                                                options={productOptions}
                                                onChange={handleProductChange}
                                                classNamePrefix="react-select"
                                                name="productId"
                                                id="productId"
                                                placeholder="Select Product"
                                                isSearchable
                                            />
                                            {errors.productId && <div className="invalid-feedback">{errors.productId.message}</div>}
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label htmlFor="orderQuantity" className="col-sm-3 col-form-label">Quantity</label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className={`form-control ${errors.orderQuantity ? 'is-invalid' : ''}`}
                                                {...register("orderQuantity", { required: "Quantity is required" })}
                                                id="orderQuantity"
                                                name="orderQuantity"
                                                required
                                            />
                                            {errors.orderQuantity && <div className="invalid-feedback">{errors.orderQuantity.message}</div>}
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label htmlFor="price" className="col-sm-3 col-form-label">Price</label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className={`form-control ${errors.price ? 'is-invalid' : ''}`}
                                                {...register("price", { required: "Price is required" })}
                                                id="price"
                                                name="price"
                                                required
                                            />
                                            {errors.price && <div className="invalid-feedback">{errors.price.message}</div>}
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label htmlFor="expectedDeliveryDate" className="col-sm-3 col-form-label">Expected Delivery Date</label>
                                        <div className="col-sm-9">
                                            <input
                                                type="date"
                                                className={`form-control ${errors.expectedDeliveryDate ? 'is-invalid' : ''}`}
                                                {...register("expectedDeliveryDate", {
                                                    required: "Expected Delivery Date is required",
                                                    validate: {
                                                        notPastDate: (value) => {
                                                            const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
                                                            return value >= today || "Date cannot be in the past";
                                                        }
                                                    }
                                                })}
                                                id="expectedDeliveryDate"
                                                name="expectedDeliveryDate"
                                                required
                                            />
                                            {errors.expectedDeliveryDate && <div className="invalid-feedback">{errors.expectedDeliveryDate.message}</div>}
                                        </div>
                                    </div>

                                    <div className="bx-pull-right">
                                        <button type="submit" className="btn btn-primary" disabled={!isValid}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </Main>
        </div>
    );
};

export default AddOrders;
