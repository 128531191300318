import React, { useState, useEffect, useContext } from "react";
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import AuthContext from "../../context/auth/authContext";


const ManageRoles = ({ show, handleClose, user }) => {
    const authContext = useContext(AuthContext);
    const { assignRole, removeRole, roles, getRoles, user_roles } = authContext;

    const [options, setOptions] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);

    useEffect(() => {
        // Fetch roles and user roles when the modal is shown
        if (show) {
            getRoles();
          
        }
    }, [show, getRoles, user.id]);

    useEffect(() => {
        // Set the options for react-select
        if (Array.isArray(roles)) {
            setOptions(roles.map(role => ({ value: role.id, label: role.name })));
        }

        // Set the initial selected roles once user_roles are fetched
        if (user_roles) {
            setSelectedRoles(user_roles.map(role => ({ value: role, label: role })));
        }
    }, [roles, user_roles]);

    const handleChange = (selectedOptions) => {
        setSelectedRoles(selectedOptions || []);
    };

    const handleSubmit = async () => {
        console.log( "Handlke Roles")
        const rolesToAdd = selectedRoles.map(option => option.value);
        const rolesToRemove = user_roles[user.id] ? user_roles.filter(role => !rolesToAdd.includes(role)) : [];

        // Assign new roles
        await Promise.all(rolesToAdd.map(role => assignRole(user, role)));

        // Remove old roles
        await Promise.all(rolesToRemove.map(role => removeRole(user, role)));

        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Manage Roles for {user.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Select
                    isMulti
                    value={selectedRoles}
                    onChange={handleChange}
                    options={options}
                    placeholder="Select roles..."
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ManageRoles;
