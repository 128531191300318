import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import OrdersContext from '../../context/bulk/orders/ordersContext';
import FileUpload from '../../components/util/uploader';
import { useNavigate } from 'react-router-dom';
import SweetAlertWrapper from '../../components/Layout/SweetAlert';

const LoadOrdersModal = ({ show, handleClose, deliveryPlanId }) => {
  const navigate = useNavigate();
  const ordersContext = useContext(OrdersContext);
  const { loadOrder, notification, clear_notification } = ordersContext;

  const [load, setLoad] = useState({
    deliveryPlanId: "",
    quantityLoaded: 0,
    loadingOrder: ""
  });

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });

  useEffect(() => {
    if (deliveryPlanId) {
      setLoad(prevLoad => ({
        ...prevLoad,
        deliveryPlanId: deliveryPlanId
      }));
    } else {
      navigate('/bulk/delivery-plans');
    }
  }, [deliveryPlanId, navigate]);

  useEffect(() => {
    if (notification) {
        setAlertMessage({ text: notification.text, type: notification.type });
        setShowAlert(true);
    }
}, [notification]);

  // Handle input changes
  const handleChange = (e) => {
    setLoad({
      ...load,
      [e.target.name]: e.target.value
    });
  };

  // Handle file upload change
  const handleFileUpload = (base64String) => {
    setLoad({
      ...load,
      loadingOrder: base64String
    });
  };

    // Handle SweetAlert confirmation
    const handleConfirm = () => {
      console.log('Confirmed');
      clear_notification();
      setShowAlert(false);
      handleClose(); // Close the modal after confirmation
    };

  // Handle form submission
  const handleLoadOrder = () => {
    if (load.deliveryPlanId) {
      loadOrder(load); 
    } else {
      // Handle case where deliveryPlanId is not set or is empty
      alert('Delivery Plan ID is required.');
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Load Orders</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {/* <SweetAlertWrapper
      show={showAlert}
      title="Notification"
      message={alertMessage.text}
      onConfirm={handleConfirm}
      confirmBtnText="OK"
      type={alertMessage.type}
  /> */}
        <Form>
          <Form.Group controlId="formQuantityLoaded">
            <Form.Label>Quantity Loaded</Form.Label>
            <Form.Control
              type="number"
              name="quantityLoaded"
              placeholder="Enter quantity loaded"
              value={load.quantityLoaded}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formLoadingOrder">
            <Form.Label>Loading Order</Form.Label>
            <FileUpload
              onFileUpload={handleFileUpload} // Use the custom handler for file uploads
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleLoadOrder}>
          Load Order
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoadOrdersModal;
