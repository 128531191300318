import React from 'react';
import { Link } from 'react-router-dom';

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage, indexOfFirstItem, indexOfLastItem }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const maxPageNumbersToShow = 5; // Adjust this number based on how many page numbers you want to show

  if (totalItems <= itemsPerPage) {
    // If total items are less than or equal to items per page, do not render pagination
    return null;
  }

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const halfRange = Math.floor(maxPageNumbersToShow / 2);
    let startPage = Math.max(1, currentPage - halfRange);
    let endPage = Math.min(totalPages, currentPage + halfRange);

    // Adjust start and end if the total page count is less than the max pages to show
    if (endPage - startPage + 1 < maxPageNumbersToShow) {
      if (startPage === 1) {
        endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);
      } else {
        startPage = Math.max(1, endPage - maxPageNumbersToShow + 1);
      }
    }

    // Add first page and ellipsis if needed
    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push('...');
      }
    }

    // Add the range of page numbers
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    // Add last page and ellipsis if needed
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push('...');
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <span>
        Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, totalItems)} of {totalItems} entries
      </span>
      <nav>
        <ul className="pagination">
          {generatePageNumbers().map((number, i) =>
            number === '...' ? (
              <li key={i} className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            ) : (
              <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                <Link className="page-link" onClick={() => paginate(number)}>
                  {number}
                </Link>
              </li>
            )
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
