import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import OrdersContext from '../../context/bulk/orders/ordersContext';
import FileUpload from '../../components/util/uploader';
import SweetAlertWrapper from '../../components/Layout/SweetAlert';

const OffloadOrdersModal = ({ show, handleClose, deliveryPlanId }) => {
  const ordersContext = useContext(OrdersContext);
  const { offloadOrder, notification, clear_notification } = ordersContext; // Assuming `offloadOrder` is a function in the context for offloading orders

  // Initialize state with deliveryPlanId and set default value if not provided
  const [offload, setOffload] = useState({
    deliveryPlanId: deliveryPlanId || '',
    quantityOffloaded: 0,
    loadingOrder: ""
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });

  // Update state when deliveryPlanId prop changes
  useEffect(() => {
    setOffload(prevState => ({
      ...prevState,
      deliveryPlanId: deliveryPlanId || ''
    }));
  }, [deliveryPlanId]);

  useEffect(() => {
    if (notification) {
      setAlertMessage({ text: notification.text, type: notification.type });
      setShowAlert(true);
    }
  }, [notification]);

  // Handle input changes
  const handleChange = (e) => {
    setOffload({
      ...offload,
      [e.target.name]: e.target.value
    });
  };

  // Handle offloading order
  const handleOffloadOrder = () => {
    if (offload.deliveryPlanId.trim() !== '') { // Check that deliveryPlanId is not just whitespace
      offloadOrder(offload);
    } else {
      alert('Delivery Plan ID is required.');
    }
  };

  // Handle file upload change
  const handleFileUpload = (base64String) => {
    setOffload({
      ...offload,
      loadingOrder: base64String
    });
  };

  // Handle SweetAlert confirmation
  const handleConfirm = () => {
    clear_notification()
    setShowAlert(false);
    handleClose(); // Close the modal after confirmation
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Offload Orders</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <SweetAlertWrapper
            show={showAlert}
            title="Notification"
            message={alertMessage.text}
            onConfirm={handleConfirm}
            confirmBtnText="OK"
            type={alertMessage.type}
          /> */}
          <Form>
            <Form.Group controlId="formQuantityOffloaded">
              <Form.Label>Quantity Offloaded</Form.Label>
              <Form.Control
                type="number"
                name="quantityOffloaded"
                placeholder="Enter quantity offloaded"
                value={offload.quantityOffloaded}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formLoadingOrder">
              <Form.Label>Loading Order</Form.Label>
              <FileUpload
                onFileUpload={handleFileUpload} // Use the custom handler for file uploads
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleOffloadOrder}>
            Offload Order
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OffloadOrdersModal;
