import React, { useState, useEffect, useContext } from 'react';
import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import Main from '../../components/Layout/Main';
import { Link } from 'react-router-dom';
import CustomerContext from '../../context/bulk/customers/customersContext';
import Spinner from '../../components/Layout/spinner';
import { formatDate } from '../../api/util';
import AddLocation from './AddLocation';

const ListCustomerLocations = () => {
    const customerContext = useContext(CustomerContext);
    const { customer_locations, getCustomerLocations } = customerContext;

    const [selectedCustomerId, setSelectedCustomerId] = useState('');
    const [showAddLocationModal, setShowAddLocationModal] = useState(false);
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const handleShowAddLocation = () => {

        setShowAddLocationModal(true);
    };

    const handleClose = () => {
        console.log('close');
        setShowAddLocationModal(false);
    };

    useEffect(() => {
        getCustomerLocations();
    }, []);

    const onSearch = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1); // Reset to the first page on search
    };

    // Filter customer locations based on search query
    const filteredCustomerLocations = customer_locations.filter((location) => {
        const searchTerm = search.toLowerCase();
        // Ensure the location has a valid customer object
        return location && (
            location.locationName.toLowerCase().includes(searchTerm) ||
            location.customerName.toLowerCase().includes(searchTerm) ||
            location.customerEmail.toLowerCase().includes(searchTerm) ||
            location.customerPhone.toLowerCase().includes(searchTerm)
        );
    });

    // Pagination logic
    const indexOfLastLocation = currentPage * itemsPerPage;
    const indexOfFirstLocation = indexOfLastLocation - itemsPerPage;
    const currentLocations = filteredCustomerLocations.slice(indexOfFirstLocation, indexOfLastLocation);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Calculate total pages
    const totalPages = Math.ceil(filteredCustomerLocations.length / itemsPerPage);

    return (
        <div className="niceadmin">
            <Header />
            <Sidebar page="List Customer Locations" />
            <Main>
                <AddLocation show={showAddLocationModal} handleClose={handleClose} />
                


                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">List Customer Locations</h5>
                        <Link className="btn btn-primary mx-2" id="add-location"
                            onClick={() => handleShowAddLocation()}
                        >
                            Add Customer Location
                        </Link>

                        <form>
                            <input
                                type="text"
                                className="form-control"
                                id="search"
                                value={search}
                                onChange={onSearch}
                                placeholder="Search"
                            />
                        </form>

                        <table className="table datatable">
                            <thead>
                                <tr>
                                    <th scope="col">Location ID</th>
                                    <th scope="col">Location Name</th>
                                    <th scope="col">Customer Name</th>
                                    <th scope="col">Customer Phone</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentLocations.length > 0 ? (
                                    currentLocations.map((location) => (
                                        <tr key={location.customerLocationId}>
                                            <th>{location.customerLocationId}</th>
                                            <td>{location.locationName}</td>
                                            <td>{location.customerName}</td>
                                            <td>{location.customerPhone}</td>

                                            <td className="dropdown">
                                                <button className="btn btn-light btn-sm dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Actions
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                                    <li>
                                                        <a
                                                            className="dropdown-item d-flex align-items-center"
                                                            onClick={() => handleShowAddLocation(location.customerId)}
                                                        >
                                                            <i className="bi bi-person"></i>
                                                            <span>Edit</span>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a
                                                            className="dropdown-item d-flex align-items-center"
                                                            href={`/bulk/customers/${location.customerId}`}
                                                        >
                                                            <i className="bi bi-eye"></i>
                                                            <span>View Customer</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7" className="text-center">
                                            No Customer Locations found.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        {/* Pagination Controls */}
                        <div className="d-flex justify-content-between align-items-center">
                            <span>
                                Showing {indexOfFirstLocation + 1} to{' '}
                                {Math.min(indexOfLastLocation, filteredCustomerLocations.length)} of{' '}
                                {filteredCustomerLocations.length} entries
                            </span>
                            <nav>
                                <ul className="pagination">
                                    {Array.from({ length: totalPages }, (_, i) => (
                                        <li key={i + 1} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                            <Link className="page-link" to="#" onClick={() => paginate(i + 1)}>
                                                {i + 1}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </Main>
        </div>
    );
};

export default ListCustomerLocations;
