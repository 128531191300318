import React, { useState, useEffect, useContext } from 'react';
import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import Main from '../../components/Layout/Main';
import { Link } from 'react-router-dom';
import CustomerContext from '../../context/bulk/customers/customersContext';
import Spinner from '../../components/Layout/spinner';
import AddCustomerLocation from './addCutomerLocation';
import EditCustomerModal from './editCustomer';
import { formatDate } from '../../api/util';
import SweetAlertWrapper from '../../components/Layout/SweetAlert';

const ListCustomers = () => {
  const customerContext = useContext(CustomerContext);
  const { customers, getCustomers, notification, clear_notifications } = customerContext;

  const [selectedCustomerId, setSelectedCustomerId] = useState('');
  const [showAddLocationModal, setShowAddLocationModal] = useState(false);
  const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [search, setSearch] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const handleShowAddLocation = (id) => {
    setSelectedCustomerId(id);
    setShowAddLocationModal(true);
  };

  const handleClose = () => {
    setSelectedCustomerId('');
    setShowAddLocationModal(false);
    setShowEditCustomerModal(false);
  };

  const handleShowEditCustomer = (customer) => {
    setSelectedCustomer(customer);
    setShowEditCustomerModal(true);
  };

  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    if (notification) {
        setAlertMessage({ text: notification.text, type: notification.type });
        setShowAlert(true);
    }
}, [notification]);

  const onSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1); // Reset to the first page on search
  };

  // Filter customers based on search query
  const filteredCustomers = customers.filter((customer) => {
    const searchTerm = search.toLowerCase();
    return (
      customer.customerName.toLowerCase().includes(searchTerm) ||
      customer.customerEmail.toLowerCase().includes(searchTerm) ||
      customer.customerPhone.toLowerCase().includes(searchTerm) ||
      customer.krapin.toLowerCase().includes(searchTerm)
    );
  });

  // Pagination logic
  const indexOfLastCustomer = currentPage * itemsPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - itemsPerPage;
  const currentCustomers = filteredCustomers.slice(indexOfFirstCustomer, indexOfLastCustomer);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(filteredCustomers.length / itemsPerPage);

  return (
    <div className="niceadmin">
      <Header />
      <Sidebar page="List Customer" />
      <Main>

      <SweetAlertWrapper
                        show={showAlert}
                        title="Notification"
                        message={alertMessage.text}
                        onConfirm={() => setShowAlert(false)}
                        confirmBtnText="OK"
                        type={alertMessage.type}
                    /> 
        <AddCustomerLocation
          customerId={selectedCustomerId}
          show={showAddLocationModal}
          handleClose={handleClose}
        />

<EditCustomerModal
          customer={selectedCustomer}
          show={showEditCustomerModal}
          handleClose={handleClose}

        />

        <div className="card">
          <div className="card-body">
            <h5 className="card-title">List Customers</h5>
            <Link className="btn btn-primary mx-2" id="add-user" to="/bulk/customers/add">
              Add Customer
            </Link>

            <form>
              <input
                type="text"
                className="form-control"
                id="search"
                value={search}
                onChange={onSearch}
                placeholder="Search"
              />
            </form>

            <table className="table datatable">
              <thead>
                <tr>
                  <th scope="col">Customer ID</th>
                  <th scope="col">Customer Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">KRA PIN</th>
                  <th scope="col">Date Created</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentCustomers.length > 0 ? (
                  currentCustomers.map((customer) => (
                    <tr key={customer.customerId}>
                      <th>{customer.customerId}</th>
                      <td>{customer.customerName}</td>
                      <td>{customer.customerEmail}</td>
                      <td>{customer.customerPhone}</td>
                      <td>{customer.krapin}</td>
                      <td>{formatDate(customer.dateCreated)}</td>
                      <td className="dropdown">
                        <button className="btn btn-light btn-sm dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                          Actions
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                          <li>
                            <a
                              className="dropdown-item d-flex align-items-center"
                              onClick={() => handleShowAddLocation(customer.customerId)}
                            >
                              <i className="bi bi-person"></i>
                              <span>Set Location</span>
                            </a>
                          </li>

                          <li>
                            <a
                              className="dropdown-item d-flex align-items-center"
                              href={`/bulk/customers/${customer.customerId}`}
                            >
                              <i className="bi bi-eye"></i>
                              <span>View Customer</span>
                            </a>
                          </li>

                          <li>
                            <a
                              className="dropdown-item d-flex align-items-center"
                              onClick={() => handleShowEditCustomer(customer)}
                            >
                              <i className="bi bi-pencil-square"></i>
                              <span>Edit Customer</span>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center">
                      No Customers found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="d-flex justify-content-between align-items-center ">
              <span>
                Showing {indexOfFirstCustomer + 1}  to {' '}
                {Math.min(indexOfLastCustomer, filteredCustomers.length)} of{' '} {filteredCustomers.length} entries
              </span>
              <nav>
                <ul className="pagination">
                  {
                    Array.from({ length: totalPages }, (_, i) => (
                      <li key={i + 1} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                        <Link className='page-link' to='#' onClick={() => paginate(i + 1)}>
                          {i + 1}
                        </Link>
                      </li>
                    ))}
                </ul>

              </nav>
            </div>
          </div>
        </div>
      </Main>
    </div>
  );
};

export default ListCustomers;
