const userCan = (roles, action) => {
    // check if action in user.roles array
    // console.log(action, "action")
    // console.log(roles, "roles")
    if (roles &&  Array.isArray(roles)) {
        
        return roles.includes(action);
    }
    return false;
}

export default userCan;

