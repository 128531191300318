import React from "react";
import { Button, Badge } from "react-bootstrap";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";

// import UpdateUserModal from "./UpdateUserModal"; 
// import ResetPasswordModal from "./ResetPasswordModal";
import AuthContext from "../../context/auth/authContext";
import { type } from "@testing-library/user-event/dist/type";

const UserProfile = () => {
  const authContext = React.useContext(AuthContext);
  const { user, user_roles } = authContext;



  const page = "User Profile";

  const renderRoles = () => {
    // Ensure user_roles is an array
    const userRoles = Array.isArray(user_roles) ? user_roles : [];
  
    // Split and group roles by their categories
    const categorizedRoles = userRoles.reduce((acc, role) => {
      const [prefix, category, roleName] = role.split(':');
      if (category && roleName) { // Ensure category and roleName are defined
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(roleName);
      }
      return acc;
    }, {});
  
    return (
      <div className="container py-4">
        {Object.keys(categorizedRoles).length > 0 ? (
          Object.keys(categorizedRoles).map((category) => (
            <div key={category} className="card mb-4">
              <div className="card-header bg-primary text-white">
                <h5 className="mb-0">{category}</h5>
              </div>
              <div className="card-body">
                {categorizedRoles[category].length > 0 ? (
                  <ul className="list-group">
                    {categorizedRoles[category].map((roleName) => (
                      <li key={roleName} className="list-group-item d-flex justify-content-between align-items-center">
                        {roleName}
                        
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="text-center text-muted">No Roles</div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-muted">No Roles</div>
        )}
      </div>
    );
  };
  

  return (
    <div className="niceadmin">
      <Header />
      <Sidebar page={page} />
      <Main>

    <div className="container">
      <h2>User Profile</h2>
      <div className="mb-3">
        <strong> Name:</strong> {user.name}
      </div>

      <div className="mb-3">
        <strong>Email:</strong> {user.email}
      </div>
      <div className="mb-3">
        <strong>Roles:</strong> {renderRoles()}
      </div>
    </div>
    </Main>
    </div>
  );
};

export default UserProfile;
