export const formatDate = (date) => {
  if (!date) {
    return "";
  }

  const new_date = new Date(date);
  const options = {
    year: 'numeric',
    month: 'short',  // You can change to 'short' or 'numeric' if you prefer
    day: 'numeric',
    // hour: '2-digit',
    // minute: '2-digit',
    hour12: false   // Set to true for 12-hour format with AM/PM
  };

  return new_date.toLocaleDateString('en-US', options);
};


export const handleErrorNotification = (response) => {
  if (response.status !== 200) {
    return {
      text: response,
      type: 'error'
    }
  }

  else {
    return {
      text: response.responseMessage,
      type: 'error'
    }
  }
}