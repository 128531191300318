import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import OrderContext from "../../context/bulk/orders/ordersContext";
import SweetAlertWrapper from '../../components/Layout/SweetAlert';

const AttachOrderModal = ({ show, handleClose, deliveryPlanId }) => {
    const orderContext = useContext(OrderContext);
    const { attachOrder, notification, clear_notification, unassignedOrders, getUnassignedOrders } = orderContext;

    const [selectedOrders, setSelectedOrders] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });


    useEffect(() => {
        getUnassignedOrders(); // Fetch unassigned orders on component mount
    }, []);

    useEffect(() => {
        if (notification) {
            setAlertMessage({ text: notification.text, type: notification.type });
            setShowAlert(true);
        }
    }, [notification]);

    const handleConfirm = () => {
        setShowAlert(false);
        handleClose();
    };

    const handleAttachOrder = () => {
        const orderIds = selectedOrders.map(order => order.value);
        attachOrder({
            deliveryPlanId,
            orderIds
        });
    };

    // Convert unassignedOrders to the format required by react-select
    const orderOptions = unassignedOrders.map(order => ({
        value: order.orderId,  // Assuming 'id' is the unique identifier for each order
        label: `${order.customerName} - ${order.locationName} - ${order.orderQuantity}` // Assuming 'name' is the label for each order
    }));

    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Attach Orders</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <SweetAlertWrapper
                        show={showAlert}
                        title="Notification"
                        message={alertMessage.text}
                        onConfirm={handleConfirm}
                        confirmBtnText="OK"
                        type={alertMessage.type}
                    /> */}
                    <Form>
                        <Form.Group controlId="formOrders">
                            <Form.Label>Select Orders</Form.Label>
                            <Select
                                isMulti
                                options={orderOptions}
                                value={selectedOrders}
                                onChange={setSelectedOrders}
                                placeholder="Select orders..."
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAttachOrder}>
                        Attach Orders
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AttachOrderModal;
