import React, { useState, useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import OrderContext from "../../context/bulk/orders/ordersContext"; // Assuming you have a context for managing orders
import FileUpload from '../../components/util/uploader';
import SweetAlertWrapper from '../../components/Layout/SweetAlert';

const SetOrderToDelivered = ({ show, handleClose, orderId }) => {
    const orderContext = useContext(OrderContext);
    const { deliverOrder, notification, clear_notification } = orderContext;

    const [quantityDelivered, setQuantityDelivered] = useState(0);
    const [deliveryNote, setDeliveryNote] = useState('');

    const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });

    const handleSetDelivered = () => {
        deliverOrder({ orderId, quantityDelivered, deliveryNote });
    };

    const handleFileUpload = (base64String) => {
        setDeliveryNote(base64String);
      };

          // Handle SweetAlert confirmation
    const handleConfirm = () => {
        clear_notification();
        setShowAlert(false);
        handleClose(); // Close the modal after confirmation
      };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Set Order to Delivered</Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <Form>
                    <Form.Group controlId="formQuantityDelivered">
                        <Form.Label>Quantity Delivered</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter quantity delivered"
                            value={quantityDelivered}
                            onChange={(e) => setQuantityDelivered(Number(e.target.value))}
                        />
                    </Form.Group>
                    <Form.Group controlId="formLoadingOrder">
                        <Form.Label>Loading Order</Form.Label>
                        <FileUpload
                            onFileUpload={handleFileUpload} // Use the custom handler for file uploads
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSetDelivered}>
                    Set as Delivered
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SetOrderToDelivered;
