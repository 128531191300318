import React, { useState } from 'react';

const FileUpload = ({ onFileUpload }) => {
    const [base64, setBase64] = useState('');
    const [fileName, setFileName] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                // Extract the Base64 string and include the prefix
                const base64String = reader.result;
                setBase64(base64String);
                setFileName(file.name);
                if (onFileUpload) {
                    onFileUpload(base64String);
                }
                // Discard the file (reset the input)
                event.target.value = '';
            };
            reader.readAsDataURL(file); // Convert file to base64
        } else {
            setBase64(''); // Reset base64 state if no file is selected
            setFileName(''); // Reset file name
        }
    };

    return (
        <div>
            <input 
                type="file" 
                onChange={handleFileChange} 
                aria-label="Upload your file"
            />
            {fileName && (
                <div>
                    <h4>File Name:</h4>
                    <p>{fileName}</p>
                </div>
            )}
        </div>
    );
};

export default FileUpload;
