import React, { useState, useEffect, useContext } from 'react';
import CustomerContext from '../../context/bulk/customers/customersContext';

const EditCustomerModal = ({ customer, show, handleClose }) => {
  const customerContext = useContext(CustomerContext);
  const { updateCustomer } = customerContext; // Get updateCustomer from the context

  const [formData, setFormData] = useState({
    customerName: '',
    customerEmail: '',
    customerPhone: '',
    krapin: '',
    customerType: "Bulk"

  });

  useEffect(() => {
    if (customer) {
      setFormData({
        customerName: customer.customerName || '',
        customerEmail: customer.customerEmail || '',
        customerPhone: customer.customerPhone || '',
        krapin: customer.krapin || '',
        customerType: "Bulk"
      });
    }
  }, [customer]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSave = () => {
    if (customer && customer.customerId) {
        console.log(formData, "formData")
      updateCustomer(customer.customerId, formData); // Update customer using the context function
    }
    handleClose(); // Close modal after save
  };

  return (
    <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Customer</h5>
            <button type="button" className="btn-close" onClick={handleClose}></button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="customerName" className="form-label">Customer Name</label>
              <input
                type="text"
                className="form-control"
                id="customerName"
                name="customerName"
                value={formData.customerName}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="customerEmail" className="form-label">Customer Email</label>
              <input
                type="email"
                className="form-control"
                id="customerEmail"
                name="customerEmail"
                value={formData.customerEmail}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="customerPhone" className="form-label">Customer Phone</label>
              <input
                type="text"
                className="form-control"
                id="customerPhone"
                name="customerPhone"
                value={formData.customerPhone}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="krapin" className="form-label">KRA PIN</label>
              <input
                type="text"
                className="form-control"
                id="krapin"
                name="krapin"
                value={formData.krapin}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
            <button type="button" className="btn btn-primary" onClick={handleSave}>Save changes</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCustomerModal;
